import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';
import { link } from 'fs';
import DownloadImg from 'assets/icons/success/download-icon.svg';
import { useQuizData } from 'utils/hooks';
import { UpsellsLinks } from 'utils/upsellsLinks';
import { Quiz } from 'types/quiz';
import { leadLanguage } from 'utils/functions';

interface OrderSummaryProps {
  title: string;
  upsellsKeys: string[];
  freeLabelText?: string;
  scrollToMW?: () => void;
  MWDescription?: string;
  downloadText?: string;
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 1rem;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const Heading = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 2.5rem;

  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
`;

const InnerItem = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`;

const LifetimeSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0 4.8rem;
  @media ${tablet} {
    padding: 1rem 0 0 2.7rem;
  }
`;

const LifetimeTitle = styled.p`
  color: #000;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
  width: 100%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid rgba(119, 119, 119, 0.1);
  padding: 1.5rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  upsellsKeys,
  freeLabelText,
  scrollToMW,
  MWDescription,
  downloadText,
}) => {
  const upsellsData = useQuizData('upsellsData');
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const { selected_plan, upsell_products, quiz_answers } = useSelector(
    (state: AppState) => state.user,
  );

  const isMale = quiz_answers?.gender === 'male';
  const isFemaleOver50 =
    quiz_answers?.gender === 'female' && quiz_answers?.age?.year >= 50;
  const isFemaleUnder50 =
    quiz_answers?.gender === 'female' && quiz_answers?.age?.year < 50;

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  // const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0, // Scroll to the top
      behavior: 'smooth', // Smooth scrolling
    });
  };

  const resolvePageTextContent = product => {
    if (product?.key.includes(upsellsData?.bundle)) {
      const planKeys = upsell_products.map(up => up.key);
      return {
        isBundle: true,
        links: (
          <MultipleUpsellLinkContainer>
            {planKeys.includes(upsellsData?.meal_planning) ? null : (
              <Download
                href={UpsellsLinks['meal_planning']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                Mediterranean Meal Planning on a Budget
              </Download>
            )}
            {planKeys.includes(upsellsData?.cortisol_detox) ? null : (
              <Download
                href={UpsellsLinks['cortisol_detox']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                30-Day Cortisol Detox Challenge
              </Download>
            )}
            {planKeys.includes(upsellsData?.fasting) ? null : (
              <Download
                href={UpsellsLinks['fasting']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                Fasting Made Easy
              </Download>
            )}
            {planKeys.includes(upsellsData?.mindfull_eating) ? null : (
              <Download
                href={UpsellsLinks['mindfull_eating']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                10 Steps to Mindful Eating
              </Download>
            )}
            {planKeys.includes(upsellsData?.menstrual) ||
            isMale ||
            isFemaleOver50 ? null : (
              <Download
                href={UpsellsLinks['menstrual']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                Menstrual Cycle-Based Diet Plan For Women
              </Download>
            )}
            {planKeys.includes(upsellsData?.menopause) ||
            isMale ||
            isFemaleUnder50 ? null : (
              <Download
                href={UpsellsLinks['menopause']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                The Ultimate Menopause Diet Plan
              </Download>
            )}
            {planKeys.includes(upsellsData?.get_fitter) ? null : (
              <Download
                href={UpsellsLinks['get_fitter']?.[leadLanguage(quiz)]}
                target="_blank"
              >
                <DownloadImg />
                Healthy Habits for Getting Fitter
              </Download>
            )}
          </MultipleUpsellLinkContainer>
        ),
      };
    }
    if (product?.key.includes(upsellsData?.meal_planning)) {
      return {
        img: 'upgrade/budget/card.png',
        link: [UpsellsLinks['meal_planning']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.cortisol_detox)) {
      return {
        img: 'upgrade/detox/card.png',
        link: [UpsellsLinks['cortisol_detox']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.fasting)) {
      return {
        img: 'upgrade/fasting/card.png',
        link: [UpsellsLinks['fasting']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.mindfull_eating)) {
      return {
        img: 'upgrade/mindful-eating/card.png',
        link: [UpsellsLinks['mindfull_eating']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.menstrual)) {
      return {
        img: 'upgrade/menstrual/card.png',
        link: [UpsellsLinks['menstrual']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.menopause)) {
      return {
        img: 'upgrade/menopause/card.png',
        link: [UpsellsLinks['menopause']?.[leadLanguage(quiz)]],
      };
    }
    if (product?.key.includes(upsellsData?.get_fitter)) {
      return {
        img: 'upgrade/get-fitter/card.png',
        link: [UpsellsLinks['get_fitter']?.[leadLanguage(quiz)]],
      };
    }
  };

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);
    const data = resolvePageTextContent(upsellProduct);
    if (upsell_product.key.includes(upsellsData?.lifetime)) {
      return;
    }

    if (upsell_product.key.includes('nodiet_mindway_upsell')) {
      return (
        <OrderItemContainer key={index}>
          <InnerItem>
            <StyledImg src={`success/mw-app.png`} alt="" />
            <UpsellInner>
              <ProductTitle>{upsellProduct?.name}</ProductTitle>
              {!data?.isBundle ? (
                <Download href="#" onClick={scrollToMW}>
                  <DownloadImg />
                  {downloadText ?? 'Download here'}
                </Download>
              ) : (
                data.links
              )}
            </UpsellInner>
            <TrialPriceContainer>
              <TrialPriceWrapper>
                <OldPrice>
                  {upsellProduct?.currency}
                  {upsell_product.key.includes('6-month')
                    ? (Number(upsellProduct?.finalPrice) / 2).toFixed(2)
                    : upsellProduct?.finalPrice}
                </OldPrice>
                {upsellProduct?.discountedTrialPrice === '0.00' ? (
                  <FreeLabel>{freeLabelText ?? 'Free'}</FreeLabel>
                ) : (
                  <Price>{upsellProduct?.discountedTrialPrice}</Price>
                )}
              </TrialPriceWrapper>
            </TrialPriceContainer>
          </InnerItem>
          {MWDescription && (
            <LifetimeSection>
              <LifetimeTitle>{MWDescription}</LifetimeTitle>
            </LifetimeSection>
          )}
        </OrderItemContainer>
      );
    }

    return (
      <OrderItemContainer key={index}>
        <InnerItem>
          <StyledImg
            src={
              data?.isBundle
                ? `upgrade/bundle-${quiz_answers?.gender}.png`
                : data?.img
            }
            alt=""
          />
          <UpsellInner>
            <ProductTitle>{upsellProduct.name}</ProductTitle>
            {!data?.isBundle ? (
              <Download href={data?.link} target="_blank">
                <DownloadImg />
                Download here
              </Download>
            ) : (
              data.links
            )}
          </UpsellInner>
          <Price>
            {upsellProduct.currency}
            {upsellProduct.finalPrice}
          </Price>
        </InnerItem>
      </OrderItemContainer>
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce((sum, p) => {
    if (p?.hasTrial) {
      return sum + parseFloat(p?.discountedTrialPrice || '0');
    }

    return sum + parseFloat(p?.finalPrice || '0');
  }, 0);

  const productVisiblePrice = selectedProduct?.hasTrial
    ? selectedProduct?.discountedTrialPrice
    : selectedProduct?.finalPrice;

  const totalPrice = (
    parseFloat(Number(productVisiblePrice)) + parseFloat(upsellsPrice)
  ).toFixed(2);

  const lifetimeProduct = upsell_products.find(item =>
    item.key.includes(upsellsData?.lifetime),
  );

  const localisedLifetime = getLocalisedProduct(lifetimeProduct);
  //
  return (
    <Container>
      <Heading>{title}</Heading>
      <SummaryContainer>
        <OrderItemContainer>
          <InnerItem>
            <StyledImg src="success/success-app.png" alt="" />
            <UpsellInner>
              <ProductTitle>
                {selectedProduct.name.replace('nodiet ', '')}
              </ProductTitle>
              <DownloadApp onClick={scrollToTop}>
                <DownloadImg />
                Download here
              </DownloadApp>
            </UpsellInner>
            <Price>
              {selectedProduct.currency}
              {Number(productVisiblePrice)}
            </Price>
          </InnerItem>
          {localisedLifetime.key && (
            <LifetimeSection>
              <LifetimeTitle>{localisedLifetime.name}</LifetimeTitle>
              <Price>
                {localisedLifetime.currency}
                {localisedLifetime.finalPrice}
              </Price>
            </LifetimeSection>
          )}
        </OrderItemContainer>
        {upsell_products.map(renderUpsellProducts)}
        <TotalItemContainer>
          <Total>Total</Total>
          <Total>
            {selectedProduct.currency}
            {totalPrice}
          </Total>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;

const ProductTitle = styled.p`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Price = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const Download = styled.a`
  cursor: pointer;
  color: #03a62c !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const DownloadApp = styled.p`
  cursor: pointer;
  color: #03a62c !important;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-decoration: underline !important;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &:hover {
    opacity: 0.7;
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Total = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const UpsellInner = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

const StyledImg = styled(DynamicImage)`
  width: 4.14144rem !important;
  height: fit-content;
  margin-right: 1rem;
  @media ${tablet} {
    width: 2.48488rem;
    margin-right: 0;
  }
`;

const MultipleUpsellLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TrialPriceContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const FreeLabel = styled.p`
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: #12845e;
  color: #fff;
  text-align: center;
  font-family: 'Figtree', sans-serif;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

const TrialPriceWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const OldPrice = styled(Price)`
  color: #555770;
  text-decoration: line-through;
`;
