import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';

interface WYSectionProps {
  title?: string;
  instruction?: string;
  info?: string;
  appleStoreLinkOverride?: string;
  googlePlayLinkOverride?: string;
}

const MWSection: FC<WYSectionProps> = ({
  title,
  instruction,
  info,
  appleStoreLinkOverride,
  googlePlayLinkOverride,
}) => (
  <Container>
    <Title>{title}</Title>
    <Instruction>{instruction}</Instruction>
    <TYButtonsContainer>
      <AppleStoreButton linkOverride={appleStoreLinkOverride} />
      <GooglePlayButton linkOverride={googlePlayLinkOverride} />
    </TYButtonsContainer>
    <Info>{info}</Info>
  </Container>
);

export default MWSection;

const Container = styled.div`
  background: #e6f6ea;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem 3rem;

  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Title = styled.h2`
  color: #141515;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  width: 100%;
  max-width: 48rem;
  margin-bottom: 1.5rem;

  @media ${tablet} {
    font-size: 1.5rem;
    letter-spacing: -0.024rem;
    margin-bottom: 2rem;
  }
`;

const Instruction = styled.p`
  color: #28293d;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.024rem;
  margin-bottom: 1.5rem;
`;

const Info = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.01375rem;

  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    letter-spacing: -0.024rem;
  }
`;

const TYButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.5rem;

  @media ${tablet} {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
`;
